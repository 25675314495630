<template>
  <div>
    <v-app-bar app flat height="50">
      <v-app-bar-nav-icon
        color="accent"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-fade-transition>
        <div v-if="!drawer" class="mx-5 d-none d-md-flex">
          <div v-for="item in items" :key="item.title">
            <v-menu v-if="item.group" bottom offset-x>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  small
                  color="accent"
                  v-bind="attrs"
                  v-on="on"
                  :to="item.route"
                  class="mr-1"
                >
                  <v-icon size="20px">{{ item.icon }}</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(subItem, subindex) in item.subItems"
                  :key="subindex"
                  :to="subItem.route"
                  color="accent"
                >
                  <v-list-item-content class="font-weight-medium">
                    <div class="d-flex align-center">
                      <v-icon color="accent" size="20px" class="mr-5">{{
                        subItem.icon
                      }}</v-icon>
                      <div
                        size="20px"
                        :class="
                          $route.path === subItem.route
                            ? 'font-weight-bold subtitle-2 accent--text'
                            : 'subtitle-2 accent--text'
                        "
                      >
                        {{ subItem.title }}
                      </div>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-tooltip v-else bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  text
                  small
                  color="accent"
                  class="mr-1"
                  v-bind="attrs"
                  v-on="on"
                  :to="item.route"
                >
                  <v-icon size="20px">{{ item.icon }}</v-icon>
                </v-btn>
              </template>
              <span class="caption">{{ item.title }}</span>
            </v-tooltip>
          </div>
        </div>
      </v-fade-transition>
      <v-spacer></v-spacer>
      <AttendingChip class="mx-md-2" />
      <AddActionsBtn
        v-if="currentUser.role !== 'admin'"
        class="mx-md-2 d-none d-md-flex"
      />

      <LocationSelect v-if="currentUser.role !== 'admin'" class="mx-md-2" />
      <v-fade-transition>
        <v-menu offset-x>
          <template v-slot:activator="{ on, attrs }">
            <div class="d-none d-sm-flex">
              <!-- desktop -->
              <v-btn
                text
                small
                block
                color="accent"
                v-bind="attrs"
                v-on="on"
                v-if="currentUser"
              >
                <v-icon class="mr-1"> mdi-account-circle </v-icon>
                <span style="text-transform: capitalize">
                  {{ userPrefix }} {{ currentUser.firstName }}
                  {{ currentUser.lastName }}</span
                >
              </v-btn>
            </div>

            <!-- mobile -->
            <v-btn text small class="d-flex d-sm-none" v-bind="attrs" v-on="on">
              <v-icon class="ml-1"> mdi-account-circle </v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item color="primary" to="/perfil">
              <v-list-item-content class="subtitle-2 font-weight-regular">
                Mi cuenta
              </v-list-item-content>
            </v-list-item>
            <v-list-item color="primary" @click="changeTheme()">
              <v-list-item-content
                class="subtitle-2 font-weight-regular d-flex"
              >
                <div class="d-flex align-center">
                  <div class="mr-3">Cambiar tema</div>
                  <v-icon small>
                    {{
                      $vuetify.theme.dark
                        ? "mdi-weather-night"
                        : "mdi-white-balance-sunny"
                    }}
                  </v-icon>
                </div>
              </v-list-item-content>
            </v-list-item>
            <v-list-item color="primary" @click="logout()">
              <v-list-item-content class="subtitle-2 font-weight-regular">
                Cerrar sesión
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-fade-transition>
    </v-app-bar>

    <v-navigation-drawer :color="'toolbar'" app v-model="drawer" width="175">
      <header class="d-flex align-center flex-column mx-auto my-5">
        <v-img
          aspect-ratio="1"
          src="../assets/XUMED.png"
          height="75px"
          width="100px"
          contain
        ></v-img>
      </header>
      <div class="px-3">
        <v-divider></v-divider>
      </div>
      <v-list nav class="mt-16 mt-md-4">
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          :to="item.route"
          color="accent"
        >
          <v-menu v-if="item.group">
            <template v-slot:activator="{ on, attrs }">
              <v-list-item-content
                class="font-weight-medium"
                v-bind="attrs"
                v-on="on"
              >
                <div class="d-flex align-center">
                  <v-icon size="20px" color="accent" class="mx-2">{{
                    item.icon
                  }}</v-icon>
                  <div color="accent" style="font-size: 15px">
                    {{ item.title }}
                  </div>
                </div>
              </v-list-item-content>
            </template>
            <v-list>
              <v-list-item
                v-for="(subItem, subindex) in item.subItems"
                :key="subindex"
                :to="subItem.route"
                color="accent"
              >
                <v-list-item-content class="font-weight-medium">
                  <div class="d-flex align-center">
                    <v-icon size="20px" :color="'accent'" class="ml-3 mr-4">{{
                      subItem.icon
                    }}</v-icon>
                    <div
                      :class="
                        $route.path === subItem.route
                          ? 'font-weight-bold subtitle-2'
                          : 'subtitle-2'
                      "
                      style="font-size: 15px"
                    >
                      {{ subItem.title }}
                    </div>
                  </div>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-list-item-content v-else class="font-weight-medium">
            <div class="d-flex align-center">
              <v-icon :color="'accent'" class="mx-2" size="20px">{{
                item.icon
              }}</v-icon>
              <div style="font-size: 15px" :class="'font-weight-medium'">
                {{ item.title }}
              </div>
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <div
        class="grey--text caption text-center"
        style="position: fixed; bottom: 10px; left: 30%"
      >
        XUMED © {{ currentYear }}
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import axios from "axios";
import { mapMutations, mapGetters, mapActions } from "vuex";
import moment from "moment";
import AttendingChip from "./Appointment/AttendingChip.vue";
import LocationSelect from "./Location/LocationSelect.vue";
import AddActionsBtn from "./Common/AddActionsBtn.vue";

export default {
  name: "ToolBar",
  components: {
    AttendingChip,
    LocationSelect,
    AddActionsBtn,
  },
  data() {
    return {
      drawer: true,
      mobileDrawer: false,
      currentYear: moment().format("YYYY"),
      navItems: [
        {
          title: "Doctores",
          icon: "mdi-doctor",
          route: "/doctores",
          roles: ["admin"],
        },
        {
          title: "Captaciones",
          icon: "mdi-new-box",
          route: "/captaciones",
          roles: ["admin"],
        },
        {
          title: "Especialidades",
          icon: "mdi-stethoscope",
          route: "/especialidades",
          roles: ["admin"],
        },
        {
          title: "ARS",
          icon: "mdi-hospital-box",
          route: "/ars",
          roles: ["admin"],
        },
        {
          title: "Admins",
          icon: "mdi-account-tie",
          route: "/administradores",
          roles: ["admin"],
        },
        {
          title: "Agenda",
          icon: "mdi-calendar",
          route: "/agenda",
          roles: ["doctor", "secretary"],
        },
        {
          title: "Pacientes",
          icon: "mdi-human-handsdown",
          route: "/pacientes",
          roles: ["doctor", "secretary"],
        },
        {
          title: "Finanzas",
          icon: "mdi-cash",
          route: "/finanzas",
          roles: ["doctor", "secretary"],
        },
        {
          title: "Reportes",
          icon: "mdi-finance",
          route: "/reportes",
          roles: ["doctor", "secretary"],
        },
        {
          title: "Inventario",
          icon: "mdi-package-variant",
          route: "/inventario",
          roles: ["doctor", "secretary"],
        },
        {
          title: "Configuración",
          icon: "mdi-cog",
          roles: ["doctor"],
          group: true,
          subItems: [
            {
              title: "Formularios",
              icon: "mdi-clipboard-file",
              route: "/formularios",
              roles: ["doctor"],
            },
            {
              title: "Sedes",
              icon: "mdi-map-marker",
              route: "/sedes",
              roles: ["doctor"],
            },
            {
              title: "Usuarios",
              icon: "mdi-account-group",
              route: "/usuarios",
              roles: ["doctor"],
            },
          ],
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["currentUser"]),
    items() {
      const filterByRole = (items, role) =>
        items
          .filter((item) => {
            const premiumNavItem = item.title == "Sedes";
            if (premiumNavItem) {
              return (
                this.currentUser?.doctor?.subscription == "premium" &&
                item.roles.includes(role)
              );
            }

            return item.roles.includes(role);
          })
          .map((item) => {
            if (item.subItems) {
              return {
                ...item,
                subItems: filterByRole(item.subItems, role),
              };
            }
            return item;
          });

      return filterByRole(this.navItems, this.currentUser?.role);
    },
    userPrefix() {
      const isADoctor = this.currentUser?.role === "doctor";
      if (isADoctor) {
        if (this.currentUser.doctor.gender === "M") {
          return "Dr.";
        } else if (this.currentUser.doctor.gender === "F") {
          return "Dra.";
        } else {
          return "";
        }
      } else {
        return "";
      }
    },
  },
  methods: {
    ...mapMutations(["setCurrentUser"]),
    ...mapActions(["fetchArs", "fetchSpecialties", "getConfiguration"]),
    async logout() {
      try {
        await axios.get("/api/logout");
        this.setCurrentUser(null);
        this.$router.push("/login");
      } catch (error) {
        console.log(error);
      }
    },
    changeTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem("xumed-dark-theme", this.$vuetify.theme.dark);
    },
    fetchGeneralData() {
      this.fetchArs();
      this.fetchSpecialties();

      if (this.currentUser.role === "admin") {
        this.getConfiguration();
      }
    },
  },
  watch: {
    drawer: function (value) {
      localStorage.setItem("xumed-drawer", value);
    },
  },
  created() {
    const drawer = localStorage.getItem("xumed-drawer");
    this.drawer = drawer === "false" ? false : true;

    if (window.innerWidth <= 1200) {
      this.drawer = false;
    }
  },
  mounted() {
    this.fetchGeneralData();
  },
};
</script>
