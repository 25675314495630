<template>
  <v-form @submit.prevent="handleSubmit" ref="form">
    <v-container>
      <Title :title="title" class="mb-8" />
      <v-menu
        v-model="dateMenu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            label="Fecha"
            readonly
            outlined
            :value="formattedDate"
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="date"
          locale="es-ES"
          color="primary"
          @input="dateMenu = false"
        ></v-date-picker>
      </v-menu>

      <vuetify-money
        v-if="paymentMethod !== 'Mixto'"
        v-model="payment"
        label="Importe a pagar"
        placeholder="0.00"
        :outlined="true"
        :clearable="true"
        :backgroundColor="$vuetify.theme.currentTheme.vuetifyMoneyBackground"
        :options="{
          locale: 'ja-JP',
          prefix: 'RD$. ',
          suffix: '',
          length: 9,
          precision: 2,
        }"
      />
      <v-select
        v-model="paymentMethod"
        :items="[
          {
            text: 'Efectivo',
            value: 'Efectivo',
          },
          {
            text: 'Tarjeta de crédito/débito',
            value: 'Tarjeta de crédito/débito',
          },
          {
            text: 'Transferencia bancaria',
            value: 'Transferencia bancaria',
          },
          {
            text: 'Mixto',
            value: 'Mixto',
          },
        ]"
        label="Método de pago"
        outlined
      ></v-select>
      <vuetify-money
        v-if="paymentMethod == 'Mixto'"
        v-model="cash"
        label="Efectivo"
        placeholder="0.00"
        :outlined="true"
        :clearable="true"
        :backgroundColor="$vuetify.theme.currentTheme.vuetifyMoneyBackground"
        :options="{
          locale: 'ja-JP',
          prefix: 'RD$. ',
          suffix: '',
          length: 9,
          precision: 2,
        }"
        :properties="{
          hint: '',
          appendIcon: 'mdi-cash',
        }"
      />
      <vuetify-money
        v-if="paymentMethod == 'Mixto'"
        v-model="bank"
        label="Transferencia bancaria o cheque"
        placeholder="0.00"
        :outlined="true"
        :clearable="true"
        :backgroundColor="$vuetify.theme.currentTheme.vuetifyMoneyBackground"
        :options="{
          locale: 'ja-JP',
          prefix: 'RD$. ',
          suffix: '',
          length: 9,
          precision: 2,
        }"
        :properties="{
          hint: '',
          appendIcon: 'mdi-bank',
        }"
      />
      <vuetify-money
        v-if="paymentMethod == 'Mixto'"
        v-model="creditCard"
        label="Tarjeta crédito/débito"
        placeholder="0.00"
        :outlined="true"
        :clearable="true"
        :backgroundColor="$vuetify.theme.currentTheme.vuetifyMoneyBackground"
        :options="{
          locale: 'ja-JP',
          prefix: 'RD$. ',
          suffix: '',
          length: 9,
          precision: 2,
        }"
        :properties="{
          hint: '',
          appendIcon: 'mdi-credit-card',
        }"
      />

      <v-text-field
        v-model="comment"
        type="text"
        label="Comentarios del pago"
        outlined
      ></v-text-field>

      <h4 class="d-md-flex secondary--text mb-1">
        <span class="font-weight-regular">Total a pagar:</span>
        <span class="font-weight-bold ml-1">
          {{ formatMoney(totalToPay) }}
        </span>
      </h4>
      <h4 class="d-md-flex secondary--text mb-1">
        <span class="font-weight-regular">Restante a pagar:</span>
        <span class="font-weight-bold ml-1">
          {{ formatMoney(leftToPay) }}
        </span>
      </h4>
      <h4 v-if="exchange" class="d-md-flex secondary--text mb-1">
        <span class="font-weight-regular">Cambio:</span>
        <span class="font-weight-bold ml-1 error--text">
          {{ formatMoney(exchange) }}
        </span>
      </h4>
      <v-row class="mt-5">
        <v-col cols="12">
          <v-btn
            depressed
            color="primary"
            class="pa-5"
            type="submit"
            :disabled="disableBtn"
            :loading="loading"
          >
            Guardar
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { mapMutations, mapGetters } from "vuex";
import { formatCurrency, formatFloat } from "../../helpers/formatters";
import Title from "../Title.vue";

export default {
  name: "InvoicePaymentForm",
  components: {
    Title
  },
  props: ["invoice"],
  data() {
    return {
      display: 0,
      title: "",
      action: "update",
      id: null,
      description: "",
      cash: "",
      creditCard: "",
      bank: "",
      comment: "",
      type: "Contado",
      itemPrice: "",
      itemQuantity: 1,
      itemDescountPercent: "",
      payment: "",
      paymentMethod: "Efectivo",
      items: [],
      itemAction: "add",
      itemIndex: 0,
      date: "",
      dateMenu: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["currentUser"]),
    formattedDate() {
      return this.date
        ? moment(this.date).format("DD/MM/YYYY")
        : moment().format("DD/MM/YYYY");
    },
    dateToSave() {
      return this.date
        ? moment(this.date).format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD");
    },
    totalToPay() {
      const invoiceTotal = this.invoice?.total || 0;
      const paid = this.invoice?.totalPaid || 0;
      return formatFloat(invoiceTotal - paid);
    },
    totalPaid() {
      let total = 0;
      total += formatFloat(this.cash || 0);
      total += formatFloat(this.creditCard || 0);
      total += formatFloat(this.bank || 0);
      return formatFloat(total);
    },
    payoff() {
      return formatFloat(this.totalToPay - this.totalPaid);
    },
    leftToPay() {
      const left = this.payoff;
      return left < 0 ? 0 : formatFloat(left);
    },
    exchange() {
      return this.leftToPay == 0 && this.paymentMethod == "Efectivo"
        ? formatFloat(Math.abs(this.payoff))
        : 0;
    },
    newInvoiceTotalPaid() {
      const old = formatFloat(this.invoice?.totalPaid || 0);
      const newTotalPaid = old + this.totalPaid;
      return this.exchange ? newTotalPaid - this.exchange : newTotalPaid;
    },
    disableBtn() {
      const cash = this.cash === "0.00" || this.cash > 0;
      const creditCard = this.creditCard === "0.00" || this.creditCard > 0;
      const bank = this.bank === "0.00" || this.bank > 0;
      const haveImport = cash || creditCard || bank;

      if (!haveImport) {
        return true;
      }

      if (this.paymentMethod !== "Efectivo" && this.payoff < 0) {
        return true;
      }

      return false;
    },
  },
  methods: {
    ...mapMutations(["updateAppointment", "setAlert"]),
    formatMoney(x) {
      return formatCurrency(x);
    },
    formatFloat(amount) {
      return formatFloat(amount);
    },
    setInputsValues({ item }) {
      this.id = item?.id;
      this.comment = item?.comment;
      this.date = item?.date;
      this.bank = item?.bank;
      this.cash = item?.cash;
      this.creditCard = item?.creditCard;
    },
    setPayment() {
      this.cash = 0;
      this.bank = 0;
      this.creditCard = 0;

      if (this.paymentMethod == "Efectivo") {
        this.cash = this.payment;
      }

      if (this.paymentMethod == "Tarjeta de crédito/débito") {
        this.creditCard = this.payment;
      }

      if (this.paymentMethod == "Transferencia bancaria") {
        this.bank = this.payment;
      }
    },
    handleSubmit() {
      if (this.action === "create") {
        this.createInvoicePayment();
      }

      if (this.action === "update") {
        this.updateInvoicePayment();
      }
    },
    getPayload() {
      let cash = formatFloat(this.cash || 0);
      cash = this.exchange ? cash - this.exchange : cash;

      let total = formatFloat(this.totalPaid);
      total = this.exchange ? total - this.exchange : total;

      return {
        cash,
        total,
        creditCard: formatFloat(this.creditCard || 0),
        bank: formatFloat(this.bank || 0),
        comment: this.comment,
        invoiceId: this.invoice.id,
        date: this.dateToSave,
        doctorId: this.currentUser.doctor.id,
        invoice: {
          id: this.invoice.id,
          totalPaid: formatFloat(this.newInvoiceTotalPaid),
        },
      };
    },
    async createInvoicePayment() {
      try {
        this.loading = true;

        const payload = this.getPayload();
        const { data } = await axios.post("/api/invoice_payment", payload);

        if (data.message) {
          this.setAlert({
            show: true,
            color: "success",
            icon: "mdi-check-circle",
            text: data.message,
          });
        }

        this.$emit("created", {
          item: data.item,
          newInvoiceTotalPaid: this.newInvoiceTotalPaid,
        });
      } catch (error) {
        console.log(error);
        this.setAlert({
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error.response.data.message,
        });
      } finally {
        this.loading = false;
      }
    },
    async updateInvoicePayment() {
      try {
        this.loading = true;

        const payload = this.getPayload();
        payload.id = this.id;

        const { data } = await axios.put(
          `/api/invoice_payment/${payload.id}`,
          payload
        );

        if (data.message) {
          this.setAlert({
            show: true,
            color: "success",
            icon: "mdi-check-circle",
            text: data.message,
          });
        }

        this.$emit("updated", {
          item: data.item,
        });
      } catch (error) {
        console.log(error);
        this.setAlert({
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error.response.data.message,
        });
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    paymentMethod: {
      handler() {
        this.setPayment();
      },
    },
    payment: {
      handler() {
        this.setPayment();
      },
    },
  },
};
</script>
