import Vue from "vue";
import VueRouter from "vue-router";
import axios from "axios";
import store from "../store/index.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "",
    name: "MainLayout",
    component: () => import("../components/Layouts/MainLayout.vue"),
    children: [
      {
        path: "/",
        name: "Home",
        component: () => import("../views/Home.vue"),
      },
      {
        path: "/registro",
        name: "SingUp",
        component: () => import("../views/SingUp.vue"),
      },
      {
        path: "/login",
        name: "Login",
        component: () => import("../views/Login.vue"),
      },
    ],
  },
  {
    path: "/nueva-contrasena/:token",
    name: "ResetPassword",
    component: () => import("../views/ResetPassword.vue"),
  },
  {
    path: "/legal",
    name: "Legal",
    component: () => import("../views/Legal.vue"),
  },
  {
    path: "/404",
    name: "NotFound",
    component: () => import("../views/NotFound.vue"),
  },
  {
    path: "/perfil",
    name: "Profile",
    component: () => import("../views/Profile.vue"),
    meta: {
      requiresAuth: true,
      authType: ["doctor", "secretary", "admin"],
    },
  },
  {
    path: "/agenda",
    name: "Agenda",
    component: () => import("../views/Agenda.vue"),
    meta: {
      requiresAuth: true,
      authType: ["doctor", "secretary"],
    },
  },
  {
    path: "/sedes",
    name: "Locations",
    component: () => import("../views/Locations.vue"),
    meta: {
      requiresAuth: true,
      authType: ["doctor"],
    },
  },
  {
    path: "/inventario",
    name: "Inventory",
    component: () => import("../views/Inventory.vue"),
    meta: {
      requiresAuth: true,
      authType: ["doctor", "secretary"],
    },
  },
  {
    path: "/reportes",
    name: "Report",
    component: () => import("../views/Report.vue"),
    meta: {
      requiresAuth: true,
      authType: ["doctor", "secretary"],
    },
  },
  {
    path: "/paciente/:id",
    name: "Patient",
    component: () => import("../views/Patient.vue"),
    meta: {
      requiresAuth: true,
      authType: ["doctor", "secretary"],
    },
  },
  {
    path: "/finanzas",
    name: "Finance",
    component: () => import("../views/Finance.vue"),
    meta: {
      requiresAuth: true,
      authType: ["doctor", "secretary"],
    },
  },
  {
    path: "/pacientes",
    name: "Patients",
    component: () => import("../views/Patients.vue"),
    meta: {
      requiresAuth: true,
      authType: ["doctor", "secretary"],
    },
  },
  {
    path: "/usuarios",
    name: "Users",
    component: () => import("../views/Users.vue"),
    meta: {
      requiresAuth: true,
      authType: ["doctor"],
    },
  },
  {
    path: "/formularios",
    name: "Forms",
    component: () => import("../views/Forms.vue"),
    meta: {
      requiresAuth: true,
      authType: ["doctor"],
    },
  },
  {
    path: "/doctores",
    name: "Doctors",
    component: () => import("../views/Doctors.vue"),
    meta: {
      requiresAuth: true,
      authType: ["admin"],
    },
  },
  {
    path: "/captaciones",
    name: "Contacts",
    component: () => import("../views/Contacts.vue"),
    meta: {
      requiresAuth: true,
      authType: ["admin"],
    },
  },
  {
    path: "/especialidades",
    name: "Specialties",
    component: () => import("../views/Specialties.vue"),
    meta: {
      requiresAuth: true,
      authType: ["admin"],
    },
  },
  {
    path: "/ars",
    name: "Ars",
    component: () => import("../views/Ars.vue"),
    meta: {
      requiresAuth: true,
      authType: ["admin"],
    },
  },
  {
    path: "/administradores",
    name: "Admins",
    component: () => import("../views/Admins.vue"),
    meta: {
      requiresAuth: true,
      authType: ["admin"],
    },
  },
  {
    path: "/doctor/:id",
    name: "Doctor",
    component: () => import("../views/Doctor.vue"),
    meta: {
      requiresAuth: true,
      authType: ["admin"],
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  // when route do not match any of the existing ones
  if (!to.matched.length) {
    next({ path: "/404", query: { redirect: to.fullPath } });
  }

  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const authType = to.matched[0].meta.authType;

  // when route do not require an auth user
  if (!requiresAuth) {
    next();
  }
  // when route require an auth user
  else {
    // check if auth
    const authUser = store.getters.currentUser;
    if (authUser && authType.includes(authUser.role)) {
      next();
      return true;
    }

    try {
      // show a loading screen
      store.commit("setLoadingOverlay", true);

      // fetch the auth user from API
      const res = await axios.get("/api/currentUser");
      const { user } = res.data;

      // set the auth user in the global state
      store.commit("setCurrentUser", user);

      // when user is doctor related
      if (user.doctor) {
        // initialize some doctor values in global state
        store.dispatch("initDoctor", user.doctor);
      }

      // hide loading screen
      store.commit("setLoadingOverlay", false);

      // validate if authenticated user can go to the route base in the role
      if (authType.includes(user.role)) {
        next();
      } else {
        store.commit("setCurrentUser", null);
        next({ path: "/login", query: { redirect: to.fullPath } });
      }
    } catch (error) {
      // if there is errors then redirect to login route
      console.log(error);
      store.commit("setCurrentUser", null);
      store.commit("setLoadingOverlay", false);
      store.commit("setAlert", {
        show: true,
        color: "error",
        icon: "mdi-alert-remove",
        text:
          error?.response?.data?.message ||
          "Error al obtener datos de la sesión",
      });
      next({ path: "/login", query: { redirect: to.fullPath } });
    }
  }
});

export default router;
