<template>
  <div>
    <WarningDialog
      ref="WarningDialogRef"
      :loading="deleting"
      @action="deleteProduct"
    />

    <v-dialog
      width="1200"
      v-model="showProductForm"
      @keydown.esc="showProductForm = false"
      persistent
    >
      <v-card class="pa-sm-10 pa-5">
        <div class="text-right">
          <v-icon
            @click="
              showProductForm = false;
              chooseProduct = true;
            "
            style="cursor: pointer"
          >
            mdi-close
          </v-icon>
        </div>
        <ProductForm
          v-if="showProductForm"
          :product="product"
          :title="formTitle"
          @product-updated="handleProductUpdated"
          @product-created="handleProductCreated"
          @on-cancel="showProductForm = false"
        />
      </v-card>
    </v-dialog>

    <div class="d-md-flex align-center">
      <div class="mr-5">
        <SearchInput @search="handleSearchInput" />
      </div>
      <div class="mb-6">
        <v-btn
          title="Crear nuevo"
          color="primary"
          small
          depressed
          @click="handleClickNew"
        >
          nuevo
        </v-btn>
      </div>
    </div>

    <p class="caption pasive--text text-right">
      {{ totalProducts }} {{ totalProducts == 1 ? "resultado" : "resultados" }}
    </p>
    <v-data-table
      key="tableKey"
      :headers="listHeaders"
      :items="products"
      :loading="loading"
      loading-text="Cargando..."
      :no-results-text="'Sin datos...'"
      :no-data-text="
        filter === ''
          ? 'No hay productos o servicios registrados'
          : 'Producto o servicio no encontrado'
      "
      :class="clickable ? 'row-pointer' : ''"
      item-key="id"
      item-class="text-center"
      disable-sort
      :footer-props="{
        itemsPerPageText: 'Resultados por pág.',
        itemsPerPageAllText: 'Todos',
        pageText: '',
        showCurrentPage: true,
        itemsPerPageOptions: [15, 30, 60],
      }"
      :options.sync="options"
      :server-items-length="totalProducts"
      @click:row="clickRow"
    >
      <template slot="item.price" scope="props">
        <span class="font-weight-bold">{{
          formatMoney(props.item.price)
        }}</span>
      </template>
      <template slot="item.cost" scope="props">
        <span>{{ formatMoney(props.item.cost) }}</span>
      </template>
      <template slot="item.discount" scope="props">
        <span>{{ props.item.discount }}%</span>
      </template>
      <template slot="item.type" scope="props">
        <span v-if="props.item.type == 'product'">
          <v-chip small>Producto</v-chip>
        </span>
        <span v-if="props.item.type == 'service'">
          <v-chip small>Servicio</v-chip>
        </span>
      </template>
      <template slot="item.stock" scope="props">
        <span v-if="props.item.type == 'service'">-</span>
        <v-chip
          v-if="props.item.type == 'product'"
          :color="
            Number(props.item.stock || 0) <= Number(props.item.stockAlert || 0)
              ? 'error'
              : 'primary'
          "
          small
          dark
          style="font-size: 13px; font-weight: 500"
        >
          {{ props.item.stock }}
        </v-chip>
      </template>
      <template slot="item.updatedAt" scope="props">
        {{ formatDateAndTime(props.item.updatedAt) }}
      </template>
      <template slot="item.action" scope="props">
        <div class="d-flex justify-center">
          <v-menu bottom offset-x>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" class="mr-3" text icon>
                <v-icon size="20px">mdi-dots-vertical </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="handleClickEdit(props.item)">
                <v-list-item-content class="font-weight-medium">
                  <div class="d-flex align-center">
                    <v-icon size="18px" class="mr-3"
                      >mdi-square-edit-outline</v-icon
                    >
                    <div class="subtitle-2 font-weight-regular">Modificar</div>
                  </div>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="showWarning(props.item)">
                <v-list-item-content class="font-weight-medium">
                  <div class="d-flex align-center">
                    <v-icon size="18px" class="mr-3"> mdi-delete </v-icon>
                    <div class="subtitle-2 font-weight-regular">Eliminar</div>
                  </div>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import axios from "axios";
import { formatCurrency } from "../../helpers/formatters";
import moment from "moment";
import ProductForm from "./ProductForm.vue";
import WarningDialog from "../WarningDialog.vue";
import SearchInput from "../SearchInput.vue";

export default {
  name: "ProductList",
  components: {
    ProductForm,
    WarningDialog,
    SearchInput,
  },
  props: ["clickable", "headers"],
  data() {
    return {
      expanded: [],
      singleExpand: false,
      filter: "",
      loading: false,
      deleting: false,
      showProductForm: false,
      formTitle: "Crear",
      product: null,
      options: {},
      totalProducts: 0,
      locationId: null,
      tableKey: 0,
    };
  },
  computed: {
    ...mapGetters(["products", "currentUser", "currentLocation"]),
    listHeaders() {
      return this.headers
        ? this.headers
        : [
            { text: "Tipo", value: "type" },
            {
              text: "Nombre",
              value: "name",
            },
            { text: "Stock", value: "stock", align: "center" },
            { text: "Costo", value: "cost" },
            { text: "Precio de venta", value: "price" },
            { text: "Desc. máximo", value: "discount" },
            { text: "Última actualización", value: "updatedAt" },
            { text: "", value: "action", align: "center" },
          ];
    },
  },
  methods: {
    ...mapActions(["fetchProducts"]),
    ...mapMutations(["setAlert", "removeProduct", "pushProduct"]),
    formatDateAndTime(date) {
      return moment(date).format("DD/MM/YYYY hh:mm A");
    },
    formatMoney(x) {
      return formatCurrency(x);
    },
    clickRow(row) {
      this.$emit("product-click", row);
    },
    handleProductCreated() {
      this.showProductForm = false;

      if (this.products.length == 1) {
        this.getData({
          page: 1,
          pageSize: 15,
        });
      }
    },
    handleProductUpdated() {
      this.showProductForm = false;
    },
    handleClickEdit(item) {
      this.product = item;
      this.formTitle = "Actualizar";
      this.showProductForm = true;
    },
    handleClickNew() {
      this.product = null;
      this.formTitle = "Crear";
      this.showProductForm = true;
    },
    showWarning(item) {
      this.product = item;
      this.$refs.WarningDialogRef.description = `¿Seguro que desea eliminar ${this.product.name}?`;
      this.$refs.WarningDialogRef.show = true;
    },
    async deleteProduct() {
      try {
        this.deleting = true;
        const res = await axios.delete(`/api/product/${this.product.id}`);
        this.warning = false;
        const { message } = res.data;
        this.setAlert({
          show: true,
          color: "success",
          icon: "mdi-check-circle",
          timeout: 4000,
          text: message,
        });
        this.removeProduct(this.product);
        this.$emit("product-deleted");
      } catch (error) {
        console.log(error);
        this.setAlert({
          show: true,
          color: "error",
          icon: "mdi-alert-remove",
          text: error.response.data.message,
        });
      } finally {
        this.deleting = false;
        this.$refs.WarningDialogRef.show = false;
      }
    },
    handleSearchInput(term) {
      this.getData({ term });
    },
    async getData(params) {
      this.loading = true;

      const page = params?.page || 1;
      const pageSize = params?.pageSize || 15;

      const data = await this.fetchProducts({
        term: params?.term,
        page,
        pageSize,
        doctorId: this.currentUser.doctor.id,
        locationId: this.locationId,
      });

      this.totalProducts = data?.count || 0;
      window.scrollTo(0, 0);
      this.loading = false;
    },
  },
  watch: {
    options: {
      handler(value) {
        this.getData({
          page: value.page || 1,
          pageSize: value.itemsPerPage || 15,
        });
      },
      deep: true,
    },
    currentLocation: {
      handler() {
        this.locationId =
          this.currentLocation.id < 0 ? null : this.currentLocation.id;
      },
    },
    locationId: {
      handler() {
        this.getData();
      },
    },
  },
  mounted() {
    this.locationId =
      this.currentLocation.id < 0 ? null : this.currentLocation.id;
  },
};
</script>
