var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();_vm.title === 'Crear' ? _vm.createProduct() : _vm.editProduct()}},model:{value:(_vm.validation),callback:function ($$v) {_vm.validation=$$v},expression:"validation"}},[_c('v-container',[_c('Title',{staticClass:"mb-10",attrs:{"title":_vm.title + ' producto o servicio'}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"items":_vm.locations,"label":"Sede *","item-value":"id","item-text":(value) => value.name,"rules":_vm.rules.required,"outlined":""},model:{value:(_vm.form.location),callback:function ($$v) {_vm.$set(_vm.form, "location", $$v)},expression:"form.location"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"rules":_vm.rules.required,"items":[
              { text: 'Producto', value: 'product' },
              { text: 'Servicio', value: 'service' },
            ],"label":"Tipo *","outlined":""},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"type":"text","label":"Nombre *","rules":_vm.rules.default,"outlined":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('vuetify-money',{attrs:{"label":"Costo und.","placeholder":"0.00","outlined":true,"clearable":true,"backgroundColor":_vm.$vuetify.theme.currentTheme.vuetifyMoneyBackground,"options":{
              locale: 'ja-JP',
              prefix: 'RD$. ',
              suffix: '',
              length: 9,
              precision: 2,
            },"properties":{
              hint: '',
            }},model:{value:(_vm.form.cost),callback:function ($$v) {_vm.$set(_vm.form, "cost", $$v)},expression:"form.cost"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('vuetify-money',{attrs:{"label":"Precio de venta","placeholder":"0.00","outlined":true,"clearable":true,"backgroundColor":_vm.$vuetify.theme.currentTheme.vuetifyMoneyBackground,"options":{
              locale: 'ja-JP',
              prefix: 'RD$. ',
              suffix: '',
              length: 9,
              precision: 2,
            },"properties":{
              hint: '',
            }},model:{value:(_vm.form.price),callback:function ($$v) {_vm.$set(_vm.form, "price", $$v)},expression:"form.price"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"type":"number","label":"Máximo descuento aplicable","append-icon":"mdi-percent","outlined":""},model:{value:(_vm.form.discount),callback:function ($$v) {_vm.$set(_vm.form, "discount", $$v)},expression:"form.discount"}})],1)],1),_c('v-slide-x-reverse-transition',{attrs:{"mode":"out-in"}},[(_vm.form.type == 'product')?_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"type":"number","label":"Stock","outlined":""},model:{value:(_vm.form.stock),callback:function ($$v) {_vm.$set(_vm.form, "stock", $$v)},expression:"form.stock"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"type":"number","label":"Stock de referencia para agotamiento","outlined":""},model:{value:(_vm.form.stockAlert),callback:function ($$v) {_vm.$set(_vm.form, "stockAlert", $$v)},expression:"form.stockAlert"}})],1)],1)],1):_vm._e()]),_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"pa-5",attrs:{"depressed":"","small":"","color":"primary","type":"submit","disabled":!_vm.validation,"loading":_vm.loading}},[_vm._v(" "+_vm._s(_vm.title)+" ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }