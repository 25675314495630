export const formatFloat = (amount) =>
  parseFloat(parseFloat(amount || 0).toFixed(2));

export const formatCurrency = (value) => {
  if (!value) {
    value = 0;
  }

  value = formatFloat(value);
  value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const str = "RD$ " + value;
  return str;
};

export const formatInvoiceId = (id) => {
  return id.toString().padStart(8, "0");
};
